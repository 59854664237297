import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'page1',
    component: () => import("@/views/Page1.vue")
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import("@/views/Admin.vue")
  },
  {
    path: '/:page',
    name: 'NF ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    redirect: '/'
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
